import React, { useState, useEffect } from 'react'
import { ApplyButton } from '../components/Icons'
import { useWindowSize, useMedia } from 'react-use'
import SanityImage from './SanityImage'
import cx from 'clsx'
import { UnderlineCareer } from '../components/Icons'
import { Link } from 'gatsby'

const Hero = ({
  headline,
  _rawImage,
  link,
  subHeadline,
  isPress,
  careersLink,
}) => {
  const { width } = useWindowSize()
  const isWide = useMedia('(min-width: 768px)')
  const [heroWidth, setHeroWidth] = useState(null)

  useEffect(() => {
    if (width) {
      const loadWidth = width * 0.65
      isWide ? setHeroWidth(Math.ceil(loadWidth)) : setHeroWidth(width)
    }
  }, [width, isWide])

  return (
    <section id="hero" className="">
      <div className="w-full pt-24 pb-12 md:header-padding relative md:pb-[5%]">
        <div className="px-4 pt-9 md:flex md:px-[5%] md:pt-[2%]">
          <div className="w-3/4 stagger-container">
            <h1 className="h3">{headline}</h1>
            {subHeadline && link ? (
              careersLink ? (
                <Link
                  to="/careers/positions"
                  className="min-w-max max-w-min ml-[3.5%] relative flex flex-col my-8 self-center items-center md:ml-[5%] md:mt-16"
                >
                  <h3 className="sub max-w-fit whitespace-nowrap">
                    {subHeadline}
                  </h3>
                  <ApplyButton className="absolute w-[110%] top-0 bottom-0 mx-auto my-auto" />
                </Link>
              ) : (
                <a
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  className="min-w-max max-w-min ml-[3.5%] relative flex flex-col my-8 self-center items-center md:ml-[5%] md:mt-16"
                >
                  <h3 className="sub max-w-fit whitespace-nowrap">
                    {subHeadline}
                  </h3>
                  <ApplyButton className="absolute w-[110%] top-0 bottom-0 mx-auto my-auto" />
                </a>
              )
            ) : (
              <h3
                className={cx(
                  'max-w-fit whitespace-nowrap',
                  isPress ? 'h4-alt pl-2 pt-[8%] md:pt-[15%]' : 'pt-5 sub'
                )}
              >
                {subHeadline}
                {isPress && <UnderlineCareer />}
              </h3>
            )}
          </div>
          <div
            className="shrink md:min-w-[70%] aspect-w-5 aspect-h-3 md:hero-height md:pl-[3%] md:aspect-w-16 md:aspect-h-7"
            style={{ width: isWide && heroWidth }}
          >
            <SanityImage
              loading="eager"
              className="object-contain"
              image={_rawImage}
              maxWidth={heroWidth}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
